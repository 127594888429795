import { Component, Input, OnInit } from '@angular/core';
import { RoomCleaningModel } from '../../../models/room-cleaning.model';
import { RandomListsService } from '../../random-lists.service';

@Component({
  selector: 'app-print-page',
  templateUrl: './print-page.component.html',
  styleUrls: ['./print-page.component.css']
})
export class PrintPageComponent implements OnInit {
  @Input() printType:
    | 'checkIn'
    | 'cleanRoom'
    | 'workLoad'
    | 'extraPay'
    | 'reservationPay'
    | 'reservationCheckIn'
    | 'billPay';
  @Input() title: string;
  @Input() roomCleaningList: RoomCleaningModel[] = [];
  @Input() checkInData;
  @Input() extraPayData;
  @Input() reservationPayData;
  @Input() reservationCheckInData;
  @Input() billPayData;
  @Input() checkOutBillData;
  hotelName = sessionStorage.getItem('hotelName');
  employeeName;
  currentDateTime;

  constructor(private randomLists: RandomListsService) {}

  ngOnInit(): void {
    this.currentDateTime = this.randomLists.getCurrentDateTime();
    const currentUser = JSON.parse(localStorage.getItem('employee'));
    this.employeeName = currentUser?.employeeName;
    // console.log('print type', this.printType);
  }
}
