import { Component, OnInit } from '@angular/core';
import { navItems } from '../../navs/defaultNav';
import { AuthService } from '../../shared/auth.service';
import { EmployeeModel } from '../../models/employee.model';
import { HttpService } from '../../shared/http.service';
import { HotelModel } from '../../models/hotel.model';
import { frontDeskNavItems } from '../../navs/frontDeskNav';
import { adminNavItems } from '../../navs/adminNav';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { registerOperatorNavItems } from '../../navs/registerOperatorNav';
import * as moment from 'moment';
import { RandomListsService } from '../../shared/random-lists.service';
import { accountantNavItems } from '../../navs/accountantNav';
import { superAdminNavItems } from '../../navs/superAdminNav';
import { marketerNavItems } from '../../navs/marketerNav';
import { housekeeperNavItems } from '../../navs/houseKeeperNav';
import {superAccountantNav} from '../../navs/superAccountantNav';
import { ipmsLightModeNavItems } from '../../navs/ipmsLightMode';
import { customerServiceNavItems } from '../../navs/customerServiceNav';
import { marketerAccountantNav } from '../../navs/marketerAccountantNav';
import { maintenanceNavItems } from '../../navs/maintenanceNav';
import { waiterFDNavItems } from '../../navs/waiterFDNav';
import { supervisorNavItems } from '../../navs/supervisorNav';
import { hodAccountsNavItems } from '../../navs/HOD-Accounts';
import { hodFbNavItems } from '../../navs/HOD-Fb';
import { hodFdNavItems } from '../../navs/HOD-Fd';
import { hodHkNavItems } from '../../navs/HOD-Hk';
import { hodMaintenanceNavItems } from '../../navs/HOD-Maintenance';
import { hodSecurityNavItems } from '../../navs/HOD-Security';
import { hodOthersNavItems } from '../../navs/HOD-Others';
import { hotelsSuperAdminNavItems } from '../../navs/hotelsSuperAdmin';
import { cardKeyAdminNavItems } from '../../navs/cardKeyAdminNav';
import { AsyncFunctionsService } from '../../shared/async-functions.service';
import {
  ACCOUNT_SECTION_BREAKER,
  ACCOUNTS_NAV,
  ACCOUNTS_NAV_CHILDREN, ADMIN_SECTION_BREAKER,
  CASH_REGISTER_NAV,
  CASH_REGISTER_NAV_CHILDREN,
  CUSTOMER_SERVICE_NAV,
  CUSTOMER_SERVICE_NAV_CHILDREN, CUSTOMER_SERVICES_NAV, CUSTOMER_SERVICES_NAV_CHILDREN,
  DASHBOARD_NAV, EMPLOYEES_NAV, EMPLOYEES_NAV_CHILDREN,
  GUESTS_NAV,
  GUESTS_NAV_CHILDREN,
  HOTELS_NAV,
  HOTELS_NAV_CHILDREN,
  HR_NAV,
  HR_NAV_CHILDREN,
  MIS_NAV,
  MIS_NAV_CHILDREN,
  REPORTS_NAV,
  REPORTS_NAV_CHILDREN,
  ROOMS_NAV,
  ROOMS_NAV_CHILDREN, SYSTEM_TABLE_NAV, SYSTEMS_NAV_CHILDREN
} from '../../shared/constants/sideNavs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  navItems;
  employee: EmployeeModel = new EmployeeModel();
  hotel: HotelModel = new HotelModel();
  registerId = localStorage.getItem('registerId');
  employeeId = localStorage.getItem('employeeId');
  hotelMode = sessionStorage.getItem('hotelMode');
  breadCrumImg;

  constructor(
    private authService: AuthService,
    private httpService: HttpService,
    private toastr: ToastrService,
    private router: Router,
    private randomList: RandomListsService,
    private asyncFunctions: AsyncFunctionsService,
  ) {}

  async ngOnInit() {
    this.getEmployeeDetails();
    this.getHotelInfo();
    await this.performUserAuthorization();

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logOut() {
    this.registerId = localStorage.getItem('registerId');
    this.insertLogOutDetails();
  }

  insertLogOutDetails() {
    const momentDateTime = moment().format('YYYY-MM-DD HH:mm:00');
    const logoutDateTime = this.randomList.parseDateTime(momentDateTime);
    const details = {
      employeeId: this.employeeId,
      logoutDateTime
    };
    this.authService
      .insertLogoutDetails('employees/insertLogout', details)
      .subscribe(
        res => {
          this.authService.logOut();
        },
        err => {
          console.log(err);
        }
      );
  }

  getEmployeeDetails() {
    const employeeId = localStorage.getItem('employeeId');
    const businessId = localStorage.getItem('businessId');
    let path;
    if (businessId) {
      path = `employees/${employeeId}?businessId=${businessId}`;
    } else {
      path = `employees/${employeeId}`;
    }

    this.httpService.getEmployeeDetails(path).subscribe(
      res => {
        this.employee = res;
        console.log('res switch hotels', res);
      },
      err => {
        console.log(err);
      }
    );
  }

  async composeNavItems() {
    // @ts-ignore
    const { permissions } = await this.asyncFunctions.getAccessRoleData();
    const userNavItems = [];
    console.log('permissions', permissions);
    for (const permission of permissions) {
      const permissionOptions = permission.options.map((e) => e.optionValue);
      if (permission.permissionValue === 'dashboard') {
        userNavItems.push(DASHBOARD_NAV);
      } else if (permission.permissionValue === 'room') {
        const roomNav = { ...ROOMS_NAV };
        roomNav.children = ROOMS_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(roomNav);
      } else if (permission.permissionValue === 'guests') {
        const guestNav = {...GUESTS_NAV };
        guestNav.children = GUESTS_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(guestNav);
      } else if (permission.permissionValue === 'cash-register') {
        const cashRegisterNav = { ...CASH_REGISTER_NAV };
        cashRegisterNav.children = CASH_REGISTER_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(cashRegisterNav);
      } else if (permission.permissionValue === 'customer-service') {
        const customerServiceNav = { ...CUSTOMER_SERVICE_NAV };
        customerServiceNav.children = CUSTOMER_SERVICE_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(customerServiceNav);
      } else if (permission.permissionValue === 'mis') {
        const misNav = { ...MIS_NAV };
        misNav.children = MIS_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(misNav);
      } else if (permission.permissionValue === 'reports') {
        const reportNav = { ...REPORTS_NAV };
        reportNav.children = REPORTS_NAV_CHILDREN.filter(
          (e) => permissionOptions.includes(e.id)
        );
        userNavItems.push(reportNav);
      } else if (permission.permissionValue === 'accounting') {
        const accountNav = { ...ACCOUNTS_NAV };
        accountNav.children = ACCOUNTS_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(ACCOUNT_SECTION_BREAKER);
        userNavItems.push(accountNav);
      } else if (permission.permissionValue === 'hr') {
        const hrNav = { ...HR_NAV };
        hrNav.children = HR_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(hrNav);
      } else if (permission.permissionValue === 'hotels') {
        const hotelsNav = { ...HOTELS_NAV };
        hotelsNav.children = HOTELS_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(ADMIN_SECTION_BREAKER);
        userNavItems.push(hotelsNav);
      } else if (permission.permissionValue === 'employees') {
        const employeeNav = { ...EMPLOYEES_NAV };
        employeeNav.children = EMPLOYEES_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(employeeNav);
      } else if (permission.permissionValue === 'system_screens') {
        const systemNav = { ...SYSTEM_TABLE_NAV };
        systemNav.children = SYSTEMS_NAV_CHILDREN.filter((e) =>
          permissionOptions.includes(e.id)
        );
        userNavItems.push(systemNav);
      } else if (permission.permissionValue === 'customer_services') {
              const customerServiceNav = { ...CUSTOMER_SERVICES_NAV };
              customerServiceNav.children = CUSTOMER_SERVICES_NAV_CHILDREN.filter((e) =>
                permissionOptions.includes(e.id)
              );
              userNavItems.push(customerServiceNav);
      }

    }
    return userNavItems;
  }

  async performUserAuthorization() {
    this.navItems = await this.composeNavItems();
  }

  switchHotels() {
    localStorage.removeItem('registerId');
    this.router.navigate(['/hotels']);
  }

  viewProfile() {
    const employeeId = localStorage.getItem('employeeId');
    this.router.navigate([`admin/employees/${employeeId}`]);
  }

  getHotelInfo() {
    const hotelId = localStorage.getItem('hotelId');
    if (+hotelId === 0) {
      this.hotel.hotelName = 'No hotel';
    } else {
      this.httpService.getHotelInfo(`hotels/${hotelId}`).subscribe(
        res => {
          this.hotel = res;
        },
        err => {
          console.log(err);
        }
      );
    }
  }


  // getEmployeeDetails() {
  //   const employeeId = localStorage.getItem('employeeId');
  //   const hotelId = localStorage.getItem('hotelId');
  //   let path;
  //   if (hotelId) {
  //     path = `employees/${employeeId}?hotelId=${hotelId}`;
  //   } else {
  //     path = `employees/${employeeId}`;
  //   }
  //
  //   this.httpService.getEmployeeDetails(path).subscribe(
  //     res => {
  //       this.employee = res;
  //       // console.log('default layouts', this.employee);
  //       if (this.employee.accessRole === 'front desk' || this.employee.accessRole === 'fb') {
  //         this.navItems = frontDeskNavItems;
  //       } else if (this.employee.accessRole === 'admin') {
  //         this.navItems = adminNavItems;
  //       }
  //       else if (this.employee.accessRole === 'supervisor') {
  //         this.navItems = supervisorNavItems;
  //       }else if (this.employee.hotelMode === 'light') {
  //         this.navItems = ipmsLightModeNavItems;
  //       } else if (
  //         this.employee.accessRole === 'manager' &&
  //         this.employee.operateRegister === 'true'
  //       ) {
  //         this.navItems = registerOperatorNavItems;
  //         // customerServiceNavItems
  //       } else if (this.employee.accessRole === 'customer_services') {
  //         this.navItems = customerServiceNavItems;
  //       } else if (this.employee.accessRole === 'maintenance') {
  //         this.navItems = maintenanceNavItems;
  //       }  else if (this.employee.accessRole === 'waiter_fd') {
  //         this.navItems = waiterFDNavItems;
  //       } else if (this.employee.accessRole === 'accountant') {
  //         this.navItems = accountantNavItems;
  //       } else if (this.employee.accessRole === 'marketer') {
  //         this.navItems = marketerNavItems;
  //       } else if (this.employee.accessRole === 'housekeeper' || this.employee.accessRole === 'customerservice') {
  //         this.navItems = housekeeperNavItems;
  //       } else if (this.employee.accessRole === 'super_accountant') {
  //         this.navItems = superAccountantNav;
  //       } else if (this.employee.accessRole === 'marketer_accountant') {
  //         this.navItems = marketerAccountantNav;
  //       } else if (this.employee.accessRole === 'cardkey-admin') {
  //         this.navItems = cardKeyAdminNavItems;
  //       }  else if (this.employee.accessRole === 'superadmin') {
  //         this.navItems = superAdminNavItems;
  //       }  else if (this.employee.accessRole === 'hotel-superadmin') {
  //         this.navItems = hotelsSuperAdminNavItems;
  //       } else if (this.employee.accessRole === 'hod_accounts') {
  //         this.navItems = hodAccountsNavItems;
  //       } else if (this.employee.accessRole === 'hod_fb') {
  //         this.navItems = hodFbNavItems;
  //       }
  //       else if (this.employee.accessRole === 'hod_fd') {
  //         this.navItems = hodFdNavItems;
  //       }
  //       else if (this.employee.accessRole === 'hod_houseKeeper') {
  //         this.navItems = hodHkNavItems;
  //       }
  //       else if (this.employee.accessRole === 'hod_maintenance') {
  //         this.navItems = hodMaintenanceNavItems;
  //       }
  //       else if (this.employee.accessRole === 'hod_security') {
  //         this.navItems = hodSecurityNavItems;
  //       }
  //       else if (this.employee.accessRole === 'hod_others') {
  //         this.navItems = hodOthersNavItems;
  //       }
  //       else {
  //         this.navItems = [];
  //       }
  //
  //       if (this.employee.picture === null) {
  //         this.employee.picture = '/assets/no_pix1.png';
  //       }
  //
  //       if (this.employee.hotelMode === 'light') {
  //         this.breadCrumImg =  'assets/logos/ipms-light.jpeg';
  //       } else if (this.employee.hotelMode === 'golden') {
  //         this.breadCrumImg =  'assets/logos/ipms-light.jpeg';
  //       } else {
  //         this.breadCrumImg =  'assets/logos/ipms-complete.jpeg';
  //       }
  //
  //     },
  //     err => {
  //       console.log(err);
  //     }
  //   );
  // }
}
