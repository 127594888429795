import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PrintPageComponent } from './print-page/print-page.component';
import { ToastsContainer } from './toasts/toasts.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [PrintPageComponent],
  exports: [PrintPageComponent],
  imports: [CommonModule, FormsModule, NgbToastModule]
})
export class ComponentsModule {}
