import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';

const APP_CONTAINERS = [DefaultLayoutComponent];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { RandomListsService } from './shared/random-lists.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PropertyDisplayComponent } from './views/property-display/property-display.component';
import { AlertModule, TooltipModule } from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { HttpInterceptorService } from './shared/http-interceptor.service';
import { AuthGuard } from './shared/auth.guard';
import { NgxPaginationModule } from 'ngx-pagination';
import { EventLogsComponent } from './views/event-logs/event-logs.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HotelMenuComponent } from './views/hotel-menu/hotel-menu.component';
import { ShiftNoteReportsComponent } from './views/reports/mgt-report/shift-note-reports/shift-note-reports.component';
import { ProjectsPageComponent } from './views/projects-page/projects-page.component';
import { CsPageComponent } from './views/cs-page/cs-page.component';
import { ComponentsModule } from './shared/components/components.module';
import { NgxPrintModule } from 'ngx-print';
// import { CashRegisterReportsComponent } from './views/reports/mgt-report/cash-register-reports/cash-register-reports.component';





@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    MDBBootstrapModule.forRoot(),
    NgbModule,
    FormsModule,
    TooltipModule.forRoot(),
    HttpClientModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 2
    }),
    AlertModule.forRoot(),
    ReactiveFormsModule,
    NgxPaginationModule,
    NgSelectModule,
    ComponentsModule,
    NgxPrintModule

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    PropertyDisplayComponent,
    EventLogsComponent,
    HotelMenuComponent,
    ProjectsPageComponent,
    CsPageComponent,
    // ShiftNoteReportsComponent
    // CashRegisterReportsComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    RandomListsService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
