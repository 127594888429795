export const DASHBOARD_NAV = {
  name: 'Dashboard',
  id: 'dashboard',
  url: '/dashboard',
  icon: 'icon-grid'
};

export const ROOMS_NAV = {
  name: 'Rooms',
  id: 'rooms',
  url: '/rooms',
  icon: 'icon-home',
  children: []
};

export const ROOMS_NAV_CHILDREN = [
  {
    name: 'Checkin',
    id: 'checkin',
    url: '/room/checkin',
    icon: 'icon-login'
  },
  {
    name: 'Checkout',
    id: 'checkout',
    url: '/room/checkout',
    icon: 'icon-logout'
  },
  {
    name: 'Make Reservation',
    id: 'make_reservation',
    url: '/room/make-reservation',
    icon: 'icon-plus'
  },
  {
    name: 'Reservations',
    id: 'reservation_checkin',
    url: '/room/reservations',
    icon: 'icon-close'
  }
];

export const GUESTS_NAV =
  {
    name: 'Guests',
    id: 'guests',
    url: '/guests',
    icon: 'icon-people',
    children: []
  };


export const GUESTS_NAV_CHILDREN = [
  {
    name: 'Guests List',
    id: 'guest_list',
    url: '/guests/guests-list',
    icon: 'icon-list'
  },
  {
    name: 'Customer Payment',
    id: 'bill_payment',
    url: '/guests/pay',
    icon: 'icon-credit-card'
  },
  {
    name: 'BFK/Mini',
    id: 'bfk_mini',
    url: '/guests/bfkmini',
    icon: 'icon-cup'
  }
];

export const CASH_REGISTER_NAV = {
  name: 'Cash Register',
  id: 'cash_register',
  icon: 'icon-notebook',
  children: []
};

export const CASH_REGISTER_NAV_CHILDREN = [
  {
    name: 'Manage Cash Register',
    id: 'manage_cash_register',
    url: '/cash-register/manage-cash-register',
    icon: 'icon-book-open'
  },
  {
    name: 'Cash Register Audit',
    id: 'cash_register_audit',
    url: '/cash-register/cash-register-audit',
    icon: 'icon-calculator'
  }
];

export const CUSTOMER_SERVICE_NAV = {
  name: 'Customer Service',
  id: 'customer_service',
  icon: 'icon-cup',
  children: []
};

export const CUSTOMER_SERVICE_NAV_CHILDREN = [
  {
    name: 'Laundry Service',
    id: 'laundry_service',
    url: '/customer-service/laundry-service',
    icon: 'icon-layers'
  },
  {
    name: 'House Keeping',
    id: 'housekeeping',
    url: '/customer-service/housekeeping',
    icon: 'icon-loop'
  },
  {
    name: 'Room Service',
    id: 'room_service',
    url: '/customer-service/room-service',
    icon: 'icon-drawer'
  },
  {
    name: 'Issues/Comments',
    id: 'issues_comments',
    url: '/customer-service/issues-comments',
    icon: 'icon-volume-1'
  }
];

export const MIS_NAV = {
  name: 'MIS',
  id: 'forms_inspection_system',
  icon: 'icon-folder-alt',
  children: []
};
export const MIS_NAV_CHILDREN = [
  {
    name: 'Staff Dashboard',
    id: 'staff_duties',
    url: '/mis/staff-duties/attendance-dashboard',
    icon: 'icon-layers'
  },
  {
    name: 'Duty Logs',
    id: 'staff_duties',
    url: '/mis/staff-duties/duty-logs',
    icon: 'icon-loop'
  },
  {
    name: 'Staffs Roster',
    id: 'staff_duties',
    url: '/mis/staff-duties/staffs-roster',
    icon: 'icon-event'
  },
  {
    name: 'Rooms Inspection',
    id: 'inspections',
    url: '/mis/inspections/rooms-list',
    icon: 'icon-doc'
  },
  {
    name: 'Room Cleaning',
    id: 'room_cleans',
    url: '/mis/house-cleaning/clean-room',
    icon: 'icon-refresh'
  },
  {
    name: 'Event Lists',
    id: 'events',
    url: '/mis/event-lists',
    icon: 'icon-event'
  }
];

export const REPORTS_NAV = {
  name: 'Reports',
  id: 'reports',
  icon: 'icon-chart',
  children: []
};

export const REPORTS_NAV_CHILDREN = [
  {
    name: 'Overview',
    id: 'overview',
    url: '/reports/overview',
    icon: 'icon-bag'
  },
  {
    name: 'Graphical Overview',
    id: 'graphical_overview',
    url: '/reports/graphical-overview',
    icon: 'icon-graph'
  },
  {
    name: 'Rooms Report',
    id: 'rooms_report',
    url: '/reports/rooms-report',
    icon: 'icon-directions'
  },
  {
    name: 'Revenues Report',
    id: 'revenues_report',
    url: '/reports/revenues-report',
    icon: 'icon-wallet'
  },
  {
    name: 'Guests Report',
    id: 'customers_report',
    url: '/reports/guests-report',
    icon: 'icon-emotsmile'
  },
  {
    name: 'Management Report',
    id: 'mgt_report',
    url: '/reports/mgt-reports',
    icon: 'icon-organization'
  },
  {
    name: 'System Report',
    id: 'system_admin_report',
    url: '/reports/system-admin-report',
    icon: 'icon-organization'
  }
];

export const ACCOUNT_SECTION_BREAKER = {
  title: true,
  name: 'Account'
};

export const ACCOUNTS_NAV = {
  name: 'Account',
  id: 'accounts_section',
  icon: 'icon-settings',
  children: []
};

export const ACCOUNTS_NAV_CHILDREN = [
  {
    name: 'Create/Edit Guest',
    id: 'edit_create_guests',
    url: '/admin/accounting/editcreate',
    icon: 'icon-user-following'
  },
  {
    name: 'Guests Ledger',
    id: 'guest_ledger',
    url: '/admin/accounting/guest-ledger-records',
    icon: 'icon-layers'
  },
  {
    name: 'Royalty Club',
    id: 'create_edit_royalty_club',
    url: '/admin/accounting/create-edit-club',
    icon: 'icon-people'
  },
  {
    name: 'Guests Refund',
    id: 'guests_refund',
    url: '/admin/accounting/refunds',
    icon: 'icon-action-redo'
  },
  {
    name: 'View Manager Credit',
    id: 'manager_credit',
    url: '/admin/accounting/view-credit',
    icon: 'icon-size-fullscreen'
  },
  {
    name: 'Petty Cash',
    id: 'petty_cash_details',
    url: '/admin/accounting/petty-cash',
    icon: 'icon-present'
  },
  {
    name: 'Petty Cash History',
    id: 'petty_cash_history',
    url: '/admin/accounting/pc-history',
    icon: 'icon-list'
  },
  {
    name: 'Cash Management',
    id: 'cash_management',
    url: '/admin/accounting/cash-management',
    icon: 'icon-doc'
  },
  {
    name: 'CM History',
    id: 'cash_management_list',
    url: '/admin/accounting/cm-history',
    icon: 'icon-docs'
  },
  {
    name: 'Bill Payment',
    id: 'bill_payment',
    url: '/admin/accounting/bill-payment',
    icon: 'icon-calculator'
  },
  {
    name: 'Process HO Payment',
    id: 'process_hotel_payment',
    url: '/admin/accounting/process-hotel-payment',
    icon: 'icon-calculator'
  },
  {
    name: 'View HO Records',
    id: 'view_process_payment',
    url: '/admin/accounting/view-process-payment',
    icon: 'icon-book-open'
  },
  {
    name: 'Card Key Records',
    id: 'cardkey_summary',
    url: '/admin/accounting/cardkey-summary',
    icon: 'icon-book-open'
  }
];

export const HR_NAV = {
  name: 'HR',
  id: 'hr',
  icon: 'icon-settings',
  children: []
};

export const HR_NAV_CHILDREN = [
  {
    name: 'View Attendance',
    id: 'attendance',
    url: '/admin/hr/attendance',
    icon: 'icon-event'
  },
  {
    name: 'Monthly Attendance',
    id: 'monthly_attendance',
    url: '/admin/hr/monthly-attendance',
    icon: 'icon-layers'
  },
  {
    name: 'View Payroll',
    id: 'payrolls',
    url: '/admin/hr/payrolls',
    icon: 'icon-film'
  }
];

export const ADMIN_SECTION_BREAKER = {
  title: true,
  name: 'Admin'
};

export const HOTELS_NAV = {
  name: 'Hotels',
  id: 'hotels',
  icon: 'icon-settings',
  children: []
};

export const HOTELS_NAV_CHILDREN = [
  {
    name: 'Create Hotel',
    id: 'create_hotel',
    url: '/admin/hotels/create',
    icon: 'icon-plus'
  },
  {
    name: 'View Hotels',
    id: 'hotels_list',
    url: '/admin/hotels/list',
    icon: 'icon-bag'
  },
  {
    name: 'Hotel Maintenance',
    id: 'hotel_assets',
    url: '/admin/hotels/assets',
    icon: 'icon-folder-alt'
  }
];

export const EMPLOYEES_NAV = {
  name: 'Employees',
  id: 'employees',
  icon: 'icon-settings',
  children: []
};

export const EMPLOYEES_NAV_CHILDREN = [
  {
    name: 'Employees Dashboard',
    id: 'employees_dashboard',
    url: '/admin/employees/hotels',
    icon: 'icon-screen-desktop'
  },
  {
    name: 'Create Employee',
    id: 'create_employee',
    url: '/admin/employees/create',
    icon: 'icon-user-follow'
  },
  {
    name: 'Surcharges',
    id: 'handle_surcharges',
    url: '/admin/employees/surcharges',
    icon: 'icon-pin'
  },
  {
    name: 'Employees Control',
    id: 'employees_control',
    url: '/admin/employees/control',
    icon: 'icon-equalizer'
  },
  {
    name: 'Roles',
    id: 'roles',
    url: '/admin/roles',
    icon: 'icon-organization'
  }
];

export const SYSTEM_TABLE_NAV = {
  name: 'System Table',
  id: 'system_screens',
  icon: 'icon-docs',
  children: []
};

export const SYSTEMS_NAV_CHILDREN = [
  {
    name: 'City/state Settings',
    id: 'city_state_settings',
    url: '/admin/system-screens/city-settings',
    icon: 'icon-settings'
  },
];

export const CUSTOMER_SERVICES_NAV = {
  name: 'Customer Service',
  id: 'customer_services',
  icon: 'icon-screen-desktop',
  children: []
};

export const CUSTOMER_SERVICES_NAV_CHILDREN = [
  {
    name: 'Summary DB',
    id: 'cs_dashboard_summary',
    url: '/admin/customer-service/cs-dashboard-summary',
    icon: 'icon-map'
  },
  {
    name: 'App Subscribers',
    id: 'app_subscribers',
    url: '/admin/customer-service/app-subscribers',
    icon: 'icon-feed'
  },
  {
    name: 'App Reservations',
    id: 'app_reservations',
    url: '/admin/customer-service/app-reservations',
    icon: 'icon-briefcase'
  },
  {
    name: 'Hotels List',
    id: 'view_hotels',
    url: '/admin/customer-service/list-hotels',
    icon: 'icon-bulb'
  },
  {
    name: 'FeedBacks',
    id: 'customer_feedbacks',
    url: '/admin/customer-service/customers-feedback',
    icon: 'icon-call-in'
  },
  {
    name: 'Requests List',
    id: 'request_list',
    url: '/admin/customer-service/requests-list',
    icon: 'icon-folder-alt'
  },
  {
    name: 'Reports',
    id: 'reports',
    url: '/admin/customer-service/reports',
    icon: 'icon-chart'
  },
  {
    name: 'Hotel Roles',
    id: 'hotel_roles',
    url: '/admin/customer-service/hotels-roles',
    icon: 'icon-shield'
  }
];







