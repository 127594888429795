import { Component, OnInit } from '@angular/core';
import { EmployeeModel } from '../../models/employee.model';
import { HttpService } from '../../shared/http.service';
import { AuthService } from '../../shared/auth.service';
import { HotelModel } from '../../models/hotel.model';
import { Router } from '@angular/router';
import { ToastService } from '../../shared/toast.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-hotel-display',
  templateUrl: './property-display.component.html',
  styleUrls: ['./property-display.component.css']
})
export class PropertyDisplayComponent implements OnInit {
  employee: EmployeeModel = new EmployeeModel();
  hotels: HotelModel[] = [];
  loading = true;
  searchKey;
  allHotels: HotelModel[];
  processing = false;
  showAlert;
  alertMessage;
  showHotelNum = false;
  alertShow = false;
  alertType;

  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.getEmployeeDetails();
  }

  logOut() {
    this.authService.logOut();
  }

  getEmployeeDetails() {
    const employeeId = localStorage.getItem('employeeId');
    this.httpService.getEmployeeDetails(`employees/${employeeId}`).subscribe(
      res => {
        this.employee = res;
        console.log('this.employee>>>>>>>>>', this.employee);
        if (this.searchKey) {
          this.getEmployeeHotels(`hotels?email=${res.emailAddress}&filterHotel=${(this.searchKey)}`);
        } else {
          this.getEmployeeHotels(`hotels?email=${res.emailAddress}`);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  filterHotel($event) {
    this.showHotelNum = true;
    this.searchKey = $event.target.value;
    // this.hotels = this.allHotels.filter((e) => e.hotelName.toLowerCase()?.indexOf(this.searchKey.toLowerCase()) > -1);

  }

  handleSearchHotels() {
    this.processing = true
    // filterHotel=${this.searchKey}
    // console.log('this.searchKey', this.searchKey);
    this.getEmployeeDetails();
  }

  getEmployeeHotels(path) {
    this.loading = true
    this.processing = true
    this.httpService.getHotels(path).subscribe(
      res => {
        this.hotels = res;

        console.log('res =<<<<<>>>>>>>', res);
        this.allHotels = res;
        if (res.length === 0) {
          this.alertMessage = `No Hotel Found`;
          this.showAlert = true;
        }
        this.loading = false;
        this.processing = false;
      },
      err => {
        console.log(err);
      }
    );
  }

  onAlertDismiss() {
    this.showAlert = false;
  }

  /*proceedToDashboard(hotel) {
    console.log('hotel', hotel);
    localStorage.setItem('hotelId', hotel.hotelId);
    sessionStorage.setItem('hotelName', hotel.hotelName);
    sessionStorage.setItem('hotelMode', hotel.hotelMode);
    sessionStorage.setItem('hotelGroupId', hotel.hotelGroupId);
    sessionStorage.setItem('hotelAccessRole', hotel.hotelAccessRole);
    sessionStorage.setItem('roleId', hotel.roleId);
    this.router.navigate(['/dashboard']);
  }*/

  proceedToDashboard(hotel) {
    if (!hotel.roleId) {

      this.toastr.warning(
        `You need an access role to access this hotel. Please ask admin to assign an access role to you.`,
        'No access Role',
        {
          timeOut: 60000,
          tapToDismiss: true
        }
      );

      /*this.showToast(
        'Error!',
        'You need an access role to access this hotel. Please ask admin to assign an access role to you.'
      );*/
      // this.alertMessage ='You need an access role to access this hotel.'
      // this.alertType = 'danger';
      // this.alertShow = true
      /*this.toastService.show(
        'Checkin Failed! Room currently occupied. Please checkout current guest.',
        'Checkin Error',
        {
          timeOut: 5000,
          tapToDismiss: true
        }
      );*/
    } else {
      console.log('hotel', hotel);
      localStorage.setItem('hotelId', hotel.hotelId);
      sessionStorage.setItem('hotelName', hotel.hotelName);
      sessionStorage.setItem('hotelMode', hotel.hotelMode);
      sessionStorage.setItem('hotelGroupId', hotel.hotelGroupId);
      sessionStorage.setItem('hotelAccessRole', hotel.hotelAccessRole);
      sessionStorage.setItem('roleId', hotel.roleId);
      this.router.navigate(['/dashboard']);
    }
  }


  onAlertClosed() {
    this.alertShow = false;
  }
}
