import { PermissionModel } from './permission.model';

export class UserRoleModel {
  roleId: string;
  roleName: string;
  hotelId: string;
  createdDateTime: string;
  createdBy: string;
  permissions: PermissionModel[];
  employeeId: string;
  accessRole: any;
}
