import { Injectable, OnInit } from '@angular/core';
import { HttpService } from './http.service';
import { ModulePermissionModel } from '../models/module-permission.models';
import { ALL_PERMISSION } from './constants/permissions';
import { UserRoleModel } from '../models/user-role.models';
import { HelperFunctionService } from './helper-functions.service';
import { EmployeeModel } from '../models/employee.model';


@Injectable({
  providedIn: 'root'
})
export class AsyncFunctionsService implements OnInit {
  hotelEmployees: EmployeeModel[] = [];

  constructor(
    private httpService: HttpService,
    private functions: HelperFunctionService,
  ) {}

  ngOnInit() {}

  async getAccessRoleData(): Promise<UserRoleModel[]> {
    try {
      // Retrieve roleId from sessionStorage
      const currentHotelRoleId = sessionStorage.getItem('roleId');
      console.log('Retrieved currentHotelRoleId:', currentHotelRoleId);

      // Handle case where roleId is null
      if (!currentHotelRoleId) {
        console.warn('No roleId found in sessionStorage.');
        return []; // Return an empty array or handle it appropriately
      }

      // Build the URL and fetch user roles
      const url = `roles/role/${currentHotelRoleId}`;
      console.log('Fetching user roles for roleId:', currentHotelRoleId);

      const userRoles = await this.httpService.getUserRoles(url).toPromise();
      return userRoles || []; // Return an empty array if no roles are found
    } catch (err) {
      console.error('Error fetching user roles:', err);
      throw new Error('Failed to retrieve user roles.');
    }
  }
 /* async getAccessRoleData(): Promise<UserRoleModel[]> {
    try {
      // Retrieve roleId from sessionStorage
      const roleIdRaw = sessionStorage.getItem('roleId');
      console.log('roleIdRaw', roleIdRaw);
      if (!roleIdRaw) {
        console.error('RoleId is missing in sessionStorage.');
        return [];
      }

      // Parse the roleId safely
      let roleId: string;
      try {
        roleId = JSON.parse(roleIdRaw);
        if (typeof roleId !== 'string') {
          console.error('Parsed roleId is not a string:', roleId);
          return [];
        }
      } catch (parseError) {
        console.error('Error parsing roleId from sessionStorage:', parseError);
        return [];
      }

      // Build the URL and fetch user roles
      const url = `roles/role/${roleId}`;
      console.log('Fetching user roles for roleId:', roleId);

      const userRoles = await this.httpService.getUserRoles(url).toPromise();
      return userRoles || []; // Return an empty array if no roles are found
    } catch (err) {
      console.error('Error fetching user roles:', err);
      throw new Error('Failed to retrieve user roles.');
    }
  }*/

  async getAccessRoleForModule(moduleName): Promise<ModulePermissionModel> {
    try {
      const accessRoles = await this.getAccessRoleData();
      // @ts-ignore
      const [userModulePermission] = accessRoles.permissions.filter(
        (e) => e.permissionValue === moduleName
      );
      const [allModulePermission] = ALL_PERMISSION.filter(
        (e) => e.value === moduleName
      );

      const userPermissionsArr =
        userModulePermission?.options?.map((e) => e.optionValue) || [];
      return allModulePermission?.options?.reduce((obj, option) => {
        obj[
          this.functions.convertWordToCamelCase(option.value)
          ] = userPermissionsArr.includes(option.value);
        return obj;
      }, {});
    } catch (err) {
      throw new Error(err);
    }
  }
}
