export const ALL_PERMISSION = [
  {
    name: 'Dashboard',
    value: 'dashboard',
    options: [
      {
        name: 'View Dashboard',
        value: 'view_dashboard',
        checked: false
      }
    ]
  },
  {
    name: 'Rooms',
    value: 'room',
    options: [
      {
        name: 'CheckIn',
        value: 'checkin',
        checked: false
      },
      {
        name: 'CheckOut',
        value: 'checkout',
        checked: false
      },
      {
        name: 'Make Reservation',
        value: 'make_reservation',
        checked: false
      },
      {
        name: 'Reservation',
        value: 'reservation_checkin',
        checked: false
      },
    ]
  },
  {
    name: 'Guest',
    value: 'guests',
    options: [
      {
        name: 'Guest Lists',
        value: 'guest_list',
        checked: false
      },
      {
        name: 'Customer Payment',
        value: 'bill_payment',
        checked: false
      },
      {
        name: 'BFK/Mini',
        value: 'bfk_mini',
        checked: false
      },
    ]
  },
  {
    name: 'Cash Register',
    value: 'cash-register',
    options: [
      {
        name: 'Manage Cash Register',
        value: 'manage_cash_register',
        checked: false
      },
      {
        name: 'Cash Register Audit',
        value: 'cash_register_audit',
        checked: false
      },
      {
        name: 'Payment',
        value: 'payment',
        checked: false
      }
    ]
  },
  {
    name: 'Customer Service',
    value: 'customer-service',
    options: [
      {
        name: 'Laundry Service',
        value: 'laundry_service',
        checked: false
      },
      {
        name: 'Housing Keeping',
        value: 'housekeeping',
        checked: false
      },
      {
        name: 'Room Service',
        value: 'room_service',
        checked: false
      },
      {
        name: 'Issues/Comments',
        value: 'issues_comments',
        checked: false
      }
    ]
  },
  {
    name: 'MIS',
    value: 'mis',
    options: [
      {
        name: 'Staff Dashboard',
        value: 'staff_duties',
        checked: false
      },
      {
        name: 'Duty Logs',
        value: 'staff_duties',
        checked: false
      },
      {
        name: 'Staffs Roaster',
        value: 'staff_duties',
        checked: false
      },
      {
        name: 'Room Inspections',
        value: 'inspections',
        checked: false
      },
      {
        name: 'Room Cleaning',
        value: 'room_cleans',
        checked: false
      },
      {
        name: 'Event Lists',
        value: 'events',
        checked: false
      }
    ]
  },
  {
    name: 'Reports',
    value: 'reports',
    options: [
      {
        name: 'Overview',
        value: 'overview',
        checked: false
      },
      {
        name: 'Graphical Overview',
        value: 'graphical_overview',
        checked: false
      },
      {
        name: 'Rooms Report',
        value: 'rooms_report',
        checked: false
      },
      {
        name: 'Revenues Report',
        value: 'revenues_report',
        checked: false
      },
      {
        name: 'Guests Report',
        value: 'customers_report',
        checked: false
      },
      {
        name: 'Managements Report',
        value: 'mgt_report',
        checked: false
      },
      {
        name: 'System Report',
        value: 'system_admin_report',
        checked: false
      }
    ]
  },
  {
    name: 'Dashboard Reports',
    value: 'dashboard_reports',
    options: [
      {
        name: 'Dashboard Reports',
        value: 'dashboard_reports',
        checked: false
      }
    ]
  },
  // {
  //   name: 'Admin Section',
  //   value: 'admin-section',
  //   options: [
  //     {
  //       name: 'Access Admin Section',
  //       value: 'access_admin_section',
  //       checked: false
  //     }
  //   ]
  // },
  {
    name: 'Accounting',
    value: 'accounting',
    options: [
      {
        name: 'Create/Edit Guest',
        value: 'edit_create_guest',
        checked: false
      },
      {
        name: 'Guest Ledger',
        value: 'guest_ledger',
        checked: false
      },
      {
        name: 'Royalty Club',
        value: 'create_edit_royalty_club',
        checked: false
      },
      {
        name: 'Guest Refund',
        value: 'guests_refund',
        checked: false
      },
      {
        name: 'View Manager Credit',
        value: 'manager_credit',
        checked: false
      },
      {
        name: 'Petty Cash',
        value: 'petty_cash_details',
        checked: false
      },
      {
        name: 'Petty Cash History',
        value: 'petty_cash_history',
        checked: false
      },
      {
        name: 'Cash Management',
        value: 'cash_management',
        checked: false
      },
      {
        name: 'CM History',
        value: 'cash_management_list',
        checked: false
      },
      {
        name: 'Bill Payment',
        value: 'bill_payment',
        checked: false
      },
      {
        name: 'Process HO Payment',
        value: 'process_hotel_payment',
        checked: false
      },
      {
        name: 'View HO Records',
        value: 'view_process_payment',
        checked: false
      },
      {
        name: 'Card Key Records',
        value: 'cardkey_summary',
        checked: false
      }
    ]
  },
  {
    name: 'Hr',
    value: 'hr',
    options: [
      {
        name: 'View Attendance',
        value: 'attendance',
        checked: false
      },
      {
        name: 'Monthly Attendance',
        value: 'monthly_attendance',
        checked: false
      },
      {
        name: 'Edit Purchases',
        value: 'edit_purchases',
        checked: false
      },
      {
        name: 'View Payroll',
        value: 'payrolls',
        checked: false
      }
    ]
  },
  {
    name: 'Admin',
    value: 'admin_section',
    options: [
      {
        name: 'Access Admin Section',
        value: 'access_admin_section',
        checked: false
      }
    ]
  },
  {
    name: 'Hotels',
    value: 'hotels',
    options: [
      {
        name: 'Create Hotel',
        value: 'create_hotel',
        checked: false
      },
      {
        name: 'View Hotels',
        value: 'hotels_list',
        checked: false
      },
      {
        name: 'Hotel Maintenance',
        value: 'hotel_assets',
        checked: false
      }
    ]
  },
  {
    name: 'Employees',
    value: 'employees',
    options: [
      {
        name: 'Employees Dashboard',
        value: 'employees_dashboard',
        checked: false
      },
      {
        name: 'Create Employee',
        value: 'create_employee',
        checked: false
      },
      {
        name: 'Surcharges',
        value: 'handle_surcharges',
        checked: false
      },
      {
        name: 'Employees Control',
        value: 'employees_control',
        checked: false
      },
      {
        name: 'Roles',
        value: 'roles',
        checked: false
      }
    ]
  },
  {
    name: 'System Table',
    value: 'system_screens',
    options: [
      {
        name: 'City/state Settings',
        value: 'city_state_settings',
        checked: false
      }
    ]
  },
  {
    name: 'Customer Service',
    value: 'customer_services',
    options: [
      {
        name: 'Summary DB',
        value: 'cs_dashboard_summary',
        checked: false
      },
      {
        name: 'App Subscribers',
        value: 'app_subscribers',
        checked: false
      },
      {
        name: 'App Reservations',
        value: 'app_reservations',
        checked: false
      },
      {
        name: 'Hotel Lists',
        value: 'view_hotels',
        checked: false
      },
      {
        name: 'FeedBacks',
        value: 'customer_feedbacks',
        checked: false
      },
      {
        name: 'Request Lists',
        value: 'request_list',
        checked: false
      },
      {
        name: 'Reports',
        value: 'reports',
        checked: false
      },
      {
        name: 'Hotel Roles',
        value: 'hotel_roles',
        checked: false
      }
    ]
  }
];
