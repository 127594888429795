export class ModulePermissionModel {
  viewDashboard?: boolean;
  viewProducts?: boolean;
  addProducts?: boolean;
  editProducts?: boolean;
  deleteProducts?: boolean;
  viewMenu?: boolean;
  addMenu?: boolean;
  editMenu?: boolean;
  deleteMenu?: boolean;
  viewUnit?: boolean;
  addUnit?: boolean;
  editUnit?: boolean;
  deleteUnit?: boolean;
  viewCategory?: boolean;
  addCategory?: boolean;
  editCategory?: boolean;
  deleteCategory?: boolean;
  viewCashRegister?: boolean;
  openCashRegister?: boolean;
  closeCashRegister?: boolean;
  viewCashRegisterAudit?: boolean;
  viewSales?: boolean;
  addSales?: boolean;
  editSales?: boolean;
  deleteSales?: boolean;
  openTable?: boolean;
  closeTable?: boolean;
  placeOrder?: boolean;
  takePayment?: boolean;
  viewPurchases?: boolean;
  addPurchases?: boolean;
  editPurchases?: boolean;
  purchaseAction?: boolean;
  paymentAction?: boolean;
  viewStockTransfer?: boolean;
  addStockTransfer?: boolean;
  editStockTransfer?: boolean;
  deleteStockTransfer?: boolean;
  transferAction?: boolean;
  viewStockAdjustment?: boolean;
  addStockAdjustment?: boolean;
  editStockAdjustment?: boolean;
  deleteStockAdjustment?: boolean;
  viewStockAudit?: boolean;
  addStockAudit?: boolean;
  editStockAudit?: boolean;
  deleteStockAudit?: boolean;
  viewRoles?: boolean;
  addRoles?: boolean;
  editRoles?: boolean;
  deleteRoles?: boolean;
  viewContacts?: boolean;
  addContacts?: boolean;
  editContacts?: boolean;
  deleteContacts?: boolean;
}
