import {Injectable} from '@angular/core';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HelperFunctionService {

  // getNumOfDays(date) {
  //   const dateNow = new Date();
  //   const date2 = new Date(date);
  //   const Difference_In_Time = date2.getTime() - dateNow.getTime();
  //   return Math.round(Difference_In_Time / (1000 * 3600 * 24));
  // }

  getCurrentHotelId() {
    const currentHotel = JSON.parse(localStorage.getItem('hotelId'));
    return currentHotel?.hotelId;
  }

  formatPhoneNumber(phoneNumber) {
    // returns phone number without country code
    return phoneNumber.substr(0, 1) === '+' ?
      phoneNumber.substr(4) : phoneNumber.substr(0, 1) === '2' ?
        phoneNumber.substr(3) : phoneNumber;
  }

  formatDBTime(DateTime) {
    // takes '2014-02-27T10:00:00'
    // returns 'DD-MMM-YYYY hh:mm'
    return moment(DateTime).format('YYYY-MM-DD');
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  convertWordToCamelCase(name) {
    const nameArr = name.split('_');
    return (
      nameArr[0] +
      nameArr
        .splice(1, nameArr.length)
        .map((word) => this.capitalizeFirstLetter(word))
        .join('')
    );
  }

  formatDateExtractMonth(DateTime) {
    // takes '2014-02-27T10:00:00'
    // returns 'DD-MMM-YYYY hh:mm'
    return moment(DateTime).format('MM');
  }

  formatTimeObjectToString(time) {
    return `${time.hour}:${time.minute}:00`;
  }

  formatDateObjectToString(date) {
    return `${date.year}-${date.month}-${date.day}`;
  }

  formatDateTimeToString(date, time) {
    return `${date.year}-${date.month}-${date.day} ${time.hour}-${time.minute}-01`;
  }

  formatDateTimeToMoment(date) {
    // 2020-12-28T10:54:00.000Z
    const dateTime = date.split(/[-T.: ]/);
    const tempDate = `${dateTime[0]}${dateTime[1]}${dateTime[2]} ${dateTime[1]}:${dateTime[2]}:00`;
    // return `${date.year}${date.month}${date.day} ${time.hour}${time.minute}01`;
    return moment(tempDate, 'YYYYMMDD HH:mm:ss').fromNow();
  }

  // formattedTimeConverter(time) {
  //   if (time < 60) {
  //     return `${time} Minutes Ago`;
  //   } else if (time > 60) {
  //     const newTime = Math.floor(time / 60);
  //     return `${newTime} Hour Ago`;
  //   }
  // }

  convertTimeInWords(date) {
    const ms = Date.now() - date.getTime();
    const seconds = Math.round(ms / 1000);
    const minutes = Math.round(ms / 60000);
    const hours = Math.round(ms / 3600000);
    const days = Math.round(ms / 86400000);
    const months = Math.round(ms / 2592000000);
    const years = Math.round(ms / 31104000000);

    switch (true) {
      case seconds < 60:
        return `${seconds} second(s) ago"`;
      case minutes < 60:
        return `${minutes} minute(s) ago"`;
      case hours < 24:
        return `${hours} hour(s) ago"`;
      case days < 30:
        return `${days} day(s) ago`;
      case months < 12:
        return `${months} month(s) ago`;
      default:
        return `${years} year(s) ago`;
    }
  }

  // getTimeInWord(time2) {
  //   const date1 = time2.split(/[-T.: ]/);
  //   // const date = new Date(`${time2[1]}/${time2[2]}/${time2[0]} ${time2[3]}:${time2[4]}:${time2[5]}`);
  //   // const date = new Date(2021, 0, 28, 14, 15, 34);
  //   const date = new Date(date1[0], date1[1] - 1, date1[2], date1[3], date1[4], date1[5]);
  //   const inWord = this.convertTimeInWords(date);
  //   console.log('time', date);
  //   console.log('inWords', inWord);
  // }

// get time in words
  getTimeInWords(dateTime) {
    const date1 = dateTime.split(/[-T.:+ ]/);
    // const date2 = new Date(`${time2[1]}/${time2[2]}/${time2[0]} ${time2[3]}:${time2[4]}:${time2[5]}`);
    const date2 = new Date(
      date1[0],
      date1[1] - 1,
      date1[2],
      date1[3],
      date1[4],
      date1[5]
    );
    const ms = Date.now() - date2.getTime();

    const seconds = Math.round(ms / 1000);
    const minutes = Math.round(ms / 60000);
    const hours = Math.round(ms / 3600000);
    const days = Math.round(ms / 86400000);
    const months = Math.round(ms / 2592000000);
    const years = Math.round(ms / 31104000000);

    if (seconds < 0) {
      switch (true) {
        case Math.abs(seconds) < 60:
          return `${seconds} second(s) ago`;
        case Math.abs(minutes) < 60:
          return `${minutes} minute(s) ago`;
        case Math.abs(hours) < 24:
          return `${hours} hour(s) ago`;
        case Math.abs(days) < 30:
          return `${days} day(s) ago`;
        case Math.abs(months) < 12:
          return `${months} month(s) ago`;
        default:
          return `${years} year(s) ago`;
      }
    } else {
      switch (true) {
        case seconds < 60:
          return `${seconds} second(s) ago`;
        case minutes < 60:
          return `${minutes} minute(s) ago`;
        case hours < 24:
          return `${hours} hour(s) ago`;
        case days < 30:
          return `${days} day(s) ago`;
        case months < 12:
          return `${months} month(s) ago`;
        default:
          return `${years} year(s) ago`;
      }
    }
  }

  // getConfirmedTimeDifference(dateTime) {
  //   const time1 = moment().format().split(/[-T.:+ ]/);
  //   const date1 = new Date(`${time1[1]}/${time1[2]}/${time1[0]} ${time1[3]}:${time1[4]}:${time1[5]}`);
  //   const time2 = dateTime.split(/[-T.:+ ]/);
  //   const date2 = new Date(`${time2[1]}/${time2[2]}/${time2[0]} ${time2[3]}:${time2[4]}:${time2[5]}`);
  //
  //
  //   // @ts-ignore
  //   const ms = Math.abs(date1 - date2);
  //   // const minutes = Math.floor(((ms % 86400000) % 3600000) / 60000); // minutes
  //   // const hours = Math.floor((ms % 86400000) / 3600000); // hours
  //   // const days = Math.floor(ms / 86400000); // days
  //   /*console.log(ms);
  //     const seconds = Math.round(ms / 1000);
  //     const minutes = Math.floor(ms / 60000);
  //     const hours = Math.floor(ms / 3600000);
  //     const days = Math.round(ms / 86400000);
  //     const months = Math.round(ms / 2592000000);
  //     const years = Math.round(ms / 31104000000);
  //        if (ms > 1000) {
  //      const seconds = Math.floor(((ms % 86400000) % 3600000) / 1000); // minutes
  //      // const seconds = Math.round(ms / 1000);
  //      return `${seconds} second(s) ago`;
  //    } else
  //    */
  //   // console.log(ms);
  //   const minute = Math.floor(( ms % (1000 * 60 * 60)) / (1000 * 60));
  //  // console.log(ms);
  //   if (minute < 60) {
  //     return `${minute} minute(s) ago`;
  //   } else if (ms >= 3600000) {
  //     const hours = Math.floor((ms % 86400000) / 3600000); // hours
  //     return `${hours} hour(s) ago`;
  //   }
  //    /*if (ms < 1000) {
  //      const sec = Math.floor(((ms % 60000) % 1000) / 60); // minutes
  //      return `${sec} second(s) ago`;
  //    } else if (ms > 60000) {
  //     const minutes = Math.floor(ms / 60000); // minutes
  //     return `${minutes} minute(s) ago`;
  //    }
  //    if (ms > 3600000) {
  //     const hours = Math.floor((ms % 86400000) / 3600000); // hours
  //     return `${hours} hour(s) ago`;
  //    }*/
  //    /* if (minutes < 60) {
  //      console.log(minutes);
  //       // const myMinutes = Math.floor(minutes / 60);
  //       return `${minutes} minute(s) ago`;
  //     } else if (minutes > 58) {
  //      // const newHours = Math.floor((minutes / 60)); // hours
  //       return `${ Math.floor((ms / 3600000))} hour(s) ago`;
  //     } else if (hours < 24) {
  //       // const myMinutes = Math.round(hours / 60);
  //        return `${hours} hour(s) ago`;
  //     } else if (hours > 24) {
  //      // const myMinutes = Math.round(hours / 60);
  //        return `${days} Day(s) ago`;
  //    }  else if (days > 30) {
  //        return `A Month Now`;
  //    }*/
  // }

  formatDateStringToObject(date) {
    const dateArr = date.split(/[-T.: ]/);
    return {
      year: +dateArr[0],
      month: +dateArr[1],
      day: +dateArr[2]
    };
  }

  formatDateTimeStringToObject(dateTime) {
    const dateArr = dateTime.split(/[-T.: ]/);
    return {date: {
        year: +dateArr[0],
        month: +dateArr[1],
        day: +dateArr[2]
      },
      time: {hour: +dateArr[3], minute: +dateArr[4], second: 5}};
  }

  // formatTimeStringToObject(time) {
  //   const timeArr = time.split(':');
  //   return {hour: +timeArr[0], minute: +timeArr[1], second: 0};
  // }
  //
  // getCurrentDateObject() {
  //   const currentDateTime = moment().format('YYYY-MM-DD HH:mm:SS');
  //   const dateArr = currentDateTime.split(/[-: ]/);
  //   return {
  //     year: +dateArr[0],
  //     month: +dateArr[1],
  //     day: +dateArr[2]
  //   };
  // }


  interpretError(err) {
    let message;
    let code;
    if (err instanceof HttpErrorResponse) {
      code = err.status;
      if (err.status === 0) {
        message =
          'An error occurred while connecting to server. Please contact tech support.';
      } else {
        message = err.error.message;
      }
    }
    return { code, message };
  }

}
